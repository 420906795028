import React from 'react';
import './Error500.css';

function Error500() {

  return (

   <>
       <div className="container_500">
        <div className="imagen_background_500">
        </div>
    </div>
   </>
  )
}

export default Error500


