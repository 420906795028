import NavLinks from "./NavLinks";
import React from "react";


const NormalNavigation = () => {

    return (
        <div className="normalNavigation">
        <NavLinks></NavLinks>
        </div>
     );
}

export default NormalNavigation;