import React from 'react';
import './ErrorPage.css';

function ErrorPage() {

  return (

   <>
       <div className="container_error">
        <div className="imagen_background_error">
        </div>
    </div>
   </>
  )
}

export default ErrorPage